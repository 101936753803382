import classNames from 'classnames';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector, useDispatch } from 'src/store';
import { selectedCompanyIdSelector, allCompanyOptionsSelector } from 'src/store/selectors/companySelector';
import {
  selectedSchemeIdSelector,
  schemeOptionsSelector,
  isLoadingSchemeSelector,
} from 'src/store/selectors/schemeSelector';
import { selectCompany } from 'src/store/slices/companySlice';
import { selectScheme } from 'src/store/slices/schemeSlice';

import Select, { SingleValue } from '@itm/shared-frontend/lib/components/react-select';

import { SelectOption } from 'src/types';

import styles from './Filters.module.scss';

function Filters() {
  const dispatch = useDispatch();
  const selectedCompanyId = useSelector(selectedCompanyIdSelector);
  const selectedSchemeId = useSelector(selectedSchemeIdSelector);
  const companyOptions = useSelector(allCompanyOptionsSelector);
  const schemeOptions = useSelector(schemeOptionsSelector);
  const isLoadingScheme = useSelector(isLoadingSchemeSelector);

  const policySearchRouteMatch = useMatch(RoutePath.landingPolicySearch);
  const reportsRouteMatch = useMatch(RoutePath.landingReports);

  const isShowFilter = useMemo(
    () => policySearchRouteMatch || reportsRouteMatch,
    [policySearchRouteMatch, reportsRouteMatch],
  );

  const selectedCompanyOption = useMemo(
    () => companyOptions.find((option) => option.value === selectedCompanyId) || null,
    [companyOptions, selectedCompanyId],
  );
  const selectedSchemeOption = useMemo(
    () => schemeOptions.find((option) => option.value === selectedSchemeId) || null,
    [schemeOptions, selectedSchemeId],
  );

  const companyChangeHandler = (newOption: SingleValue<SelectOption>) => {
    if (newOption) {
      dispatch(selectCompany(newOption.value));
    }
  };
  const schemeChangeHandler = (newOption: SingleValue<SelectOption>) => {
    if (newOption) {
      dispatch(selectScheme(newOption.value));
    }
  };

  return (
    <>
      {isShowFilter && (
        <div className={classNames(styles.SelectWrapper, 'is-flex is-justify-content-center')}>
          <div className="control">
            <Select
              className="react-select"
              classNamePrefix="react-select"
              value={selectedCompanyOption}
              options={companyOptions}
              aria-label="Company name"
              placeholder={companyOptions.length ? 'Company Name' : 'No available companies'}
              onChange={companyChangeHandler}
              isDisabled={!companyOptions.length}
              isClearable={false}
              isSearchable={true}
              closeMenuOnSelect={true}
            />
          </div>
          <div className="control">
            <Select
              className={classNames('react-select', { 'is-loading': isLoadingScheme })}
              classNamePrefix="react-select"
              value={selectedSchemeOption}
              options={schemeOptions}
              aria-label="Scheme name"
              placeholder={schemeOptions.length ? 'Scheme Name' : 'No available schemes'}
              onChange={schemeChangeHandler}
              isDisabled={isLoadingScheme || !selectedCompanyId || !schemeOptions.length}
              isClearable={false}
              isSearchable={true}
              closeMenuOnSelect={true}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Filters;
