import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { isLoggedInSelector } from 'src/store/selectors/authSelector';
import {
  tenantBrandingLogoUrlSelector,
  tenantBrandingLogoAltSelector,
} from 'src/store/selectors/tenantBrandingSelector';

import { LogoutIconSVG, MenuIcon } from '@itm/shared-frontend/lib/components/icons';
import { clientPortalDashboardUrl } from '@itm/shared-frontend/lib/utils';

import Filters from 'src/components/Filters';
import { logout } from 'src/hocs/AuthProvider';

import styles from './Header.module.scss';

function Header() {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const brandingLogoUrl = useSelector(tenantBrandingLogoUrlSelector);
  const brandingLogoAlt = useSelector(tenantBrandingLogoAltSelector);

  return (
    <header className="main-header">
      <div className="container">
        <nav className={classNames(styles.Nav, 'navbar px-3')} role="navigation" aria-label="main navigation">
          <div className="navbar-brand is-flex-grow-1 ml-0">
            <Link className="navbar-item" to={RoutePath.root}>
              <img src={brandingLogoUrl} alt={brandingLogoAlt} />
            </Link>

            {!isLoggedIn ? null : (
              <>
                <div className="navbar-item is-flex-grow-1 is-flex-shrink-1 is-justify-content-center">
                  <Filters />
                </div>

                <a
                  className="navbar-item has-icon"
                  href={clientPortalDashboardUrl}
                  rel="noreferrer noopener"
                  title="All products"
                >
                  <MenuIcon />
                </a>
                <button
                  className="button logout-button has-icon px-4"
                  type="button"
                  onClick={() => logout()}
                  title="Logout"
                >
                  <LogoutIconSVG />
                </button>
              </>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
