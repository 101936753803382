import axios from 'axios';
import { useMemo, useCallback, useEffect, useRef } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/router';

import { useSelector } from 'src/store';
import { selectedSchemeIdSelector } from 'src/store/selectors/schemeSelector';

import { FormatDate } from '@itm/shared-frontend/lib/components';
import { ViewButton } from '@itm/shared-frontend/lib/components/buttons';
import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import { ServerErrorAdapter, TanstackTableSearchParamsAdapter } from '@itm/shared-frontend/lib/utils/';
import {
  DeferredTable,
  createColumnHelper,
  useTanstackTableBasicKit,
} from '@itm/shared-frontend/lib/components/tanstack-table';

import { DataAccessPermission, isDataAccessError } from '@itm/shared-frontend/lib/components/dataAccess';
import useDataAccessPermission from 'src/hooks/useDataAccessPermission';

import { clientPortalApi } from 'src/api';
import { GetPolicyListSearchParams, getPolicyListSearch } from 'src/api/eArchive/policy';

import { PolicyIndexResponse, TanstackTableChangeParams } from 'src/types';

import styles from './PolicySearch.module.scss';

type PrevExternalTableFilters = { selectedSchemeId: string };

const columnHelper = createColumnHelper<PolicyIndexResponse>();

const columns = [
  columnHelper.accessor('policyNumber', {
    id: 'policyNumbers',
    header: 'Policy Number',
    meta: { filterType: 'textarea' },
  }),
  columnHelper.accessor('poNumber', { id: 'poNumbers', header: 'PO Number', meta: { filterType: 'textarea' } }),
  columnHelper.accessor('fullName', { header: 'Name' }),
  columnHelper.accessor('niNumber', { header: 'Ni Number' }),
  columnHelper.accessor('dateOfBirth', {
    id: 'dob',
    header: 'Date Of Birth',
    meta: { className: 'is-nowrap', filterType: 'datepicker' },
    cell: ({ getValue }) => {
      const dateOfBirth = getValue();
      return dateOfBirth ? <FormatDate date={dateOfBirth} /> : <></>;
    },
  }),
  columnHelper.accessor('commencementDate', {
    header: 'Commencement Date',
    meta: { className: 'is-nowrap', filterType: 'datepicker' },
    cell: ({ getValue }) => {
      const commencementDate = getValue();
      return commencementDate ? <FormatDate date={commencementDate} /> : <></>;
    },
  }),
  columnHelper.display({
    id: 'action',
    enableSorting: false,
    meta: { headerClassName: 'is-narrow' },
    header: ({ header }) => <span className="is-sr-only">{header.id}</span>,
    cell: ({ row }) => {
      const { policyNumber } = row.original;
      const label = `View policy ${policyNumber} details`;
      const routePath = generatePath(RoutePath.landingPolicyDetails, { policyNumber });

      return <ViewButton aria-label={label} title={label} to={routePath} />;
    },
  }),
];

function PolicySearch() {
  const { state: locationState } = useLocation();
  const selectedSchemeId = useSelector(selectedSchemeIdSelector);
  const prevExternalFilters = useRef<PrevExternalTableFilters>({ selectedSchemeId });

  const {
    data: [data, setData],
    pageCount: [pageCount, setPageCount],
    isLoading: [isLoading, setIsLoading],
    tableServerErrorMessages: [serverErrorMessages, setServerErrorMessages],
    abortControllerSet,
    tableInstanceRef,
    cleanup,
  } = useTanstackTableBasicKit<PolicyIndexResponse>();

  const shouldRestoreStateFromLS = useMemo(
    () => Object(locationState).from === RoutePath.landingPolicyDetails,
    [locationState],
  );

  const {
    companyId,
    companyName,
    productId,
    hasDataAccessToScheme,
    hasDataAccess,
    setHasDataAccess,
    setIsShowDataAccessForm,
    requestSchemeList,
    resetDataAccessState,
  } = useDataAccessPermission();

  const tableChangeHandler = useCallback(
    async (tableChangeParams: TanstackTableChangeParams) => {
      cleanup();
      setServerErrorMessages([]);
      if (!selectedSchemeId) {
        setData([]);
        setIsLoading(false);
        return;
      }
      if (prevExternalFilters.current.selectedSchemeId !== selectedSchemeId) {
        setData([]);
      }
      const abortController = new AbortController();
      abortControllerSet.add(abortController);
      prevExternalFilters.current = { selectedSchemeId };
      setIsLoading(true);
      try {
        const filters: Partial<GetPolicyListSearchParams> = tableChangeParams.columnFilters.reduce(
          (acc, { id, value }) => ({ ...acc, [id]: value }),
          {},
        );
        const params: GetPolicyListSearchParams = {
          ...new TanstackTableSearchParamsAdapter(tableChangeParams),
          schemeId: selectedSchemeId,
          policyNumbers: filters.policyNumbers,
          poNumbers: filters.poNumbers,
          fullName: filters.fullName,
          niNumber: filters.niNumber,
          dob: filters.dob,
          commencementDate: filters.commencementDate,
        };

        const config = { signal: abortController.signal };
        const res = await getPolicyListSearch(params, config);
        const { totalCount, policies } = res.data;
        setPageCount(Math.ceil(totalCount / tableChangeParams.pagination.pageSize));
        setHasDataAccess(true);
        setData(policies);
      } catch (e) {
        if (e instanceof axios.Cancel || !(e instanceof axios.AxiosError)) return;

        if (isDataAccessError(e.response)) {
          setHasDataAccess(false);
          setPageCount(0);
          setData([]);
        } else {
          const serverErrors = new ServerErrorAdapter(e);
          setServerErrorMessages(serverErrors.combine());
        }
      } finally {
        abortControllerSet.delete(abortController);
        setIsLoading(false);
      }
    },
    [
      selectedSchemeId,
      abortControllerSet,
      cleanup,
      setServerErrorMessages,
      setIsLoading,
      setData,
      setPageCount,
      setHasDataAccess,
    ],
  );

  useEffect(() => {
    setServerErrorMessages([]);
  }, [companyId, setServerErrorMessages]);

  useEffect(() => () => cleanup(), [cleanup]);

  const SuccessDataAccessButtonComponent = useMemo(
    () => () => {
      const clickHandler = async () => {
        await requestSchemeList();
        resetDataAccessState();
      };
      return (
        <button className="button is-interact" type="button" onClick={clickHandler}>
          Back to Search
        </button>
      );
    },
    [requestSchemeList, resetDataAccessState],
  );

  return (
    <>
      <ServerErrorMessages className="mb-4" messages={serverErrorMessages} />
      {!hasDataAccessToScheme || !hasDataAccess ? (
        <DataAccessPermission
          companyId={companyId}
          companyName={companyName}
          clientPortalApiInstance={clientPortalApi}
          productId={productId}
          messageButtonOnClick={() => setIsShowDataAccessForm(true)}
          SuccessButtonComponent={SuccessDataAccessButtonComponent}
        />
      ) : (
        <div className={styles.TableWrapper}>
          <DeferredTable
            uniqueKey="policyNumber"
            key={selectedSchemeId}
            data={data}
            columns={columns}
            pageCount={pageCount}
            isLoading={isLoading}
            instanceRef={tableInstanceRef}
            onParamsChange={tableChangeHandler}
            localeStorageKey="policySearchTable"
            shouldRestoreStateFromLS={shouldRestoreStateFromLS}
            enableGlobalFilter={false}
            enableColumnFilters
          />
        </div>
      )}
    </>
  );
}

export default PolicySearch;
