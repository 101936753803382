import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import Header from './Header';

import styles from './Layout.module.scss';

type Props = {
  hasHeader?: boolean;
};

function Layout({ hasHeader = false, children }: PropsWithChildren<Props>) {
  return (
    <div className={classNames(styles.Wrapper, 'is-flex is-flex-direction-column')}>
      {hasHeader ? <Header /> : null}
      <main>{children}</main>
    </div>
  );
}

export default Layout;
